import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, Layout, Root } from '../components';
import { Match } from '../old/components';
import { Page } from './page';
import { getJsonFromParams } from './tournament';

const MatchTemplate: React.FC<PageProps<null, { pages: Page[] }>> = ({
  pageContext: { pages },
}) => {
  const params =
    typeof window !== 'undefined'
      ? getJsonFromParams<{ match: string }>(location.search.substr(1))
      : {};

  return (
    <>
      <Helmet>
        <title>Yes2Chess - Match</title>
      </Helmet>
      <Root>
        <Layout>
          <Header pages={pages}>
            <Match matchId={+(params.match || 0)} />
          </Header>
        </Layout>
      </Root>
    </>
  );
};

export default MatchTemplate;
